import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto nacl auth digest`}</strong>{` -- generate a 32-byte digest for a message`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto nacl auth digest <key-file>
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto nacl auth digest`}</strong>{` creates a digest to authenticate the message
is read from STDIN using the given secret key.`}</p>
    <p>{`This command uses an implementation of NaCl's crypto_auth function.`}</p>
    <p>{`For examples, see `}<strong parentName="p">{`step help crypto nacl auth`}</strong>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      